import React, {Component} from "react";
import './HomeBanner.css'

class HomeBanner extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    scrDown = async () => {
        window.scrollTo({top: 900, behavior: 'smooth'});
    }

    render() {
        return (
            <React.Fragment>
                <div className="img-1">
                    <div className="section section-1">
                        <h1>
                            {/*Pinjaman Dana Bersahabat<br/>*/}
                            {/*<Button onClick={this.scrDown} size={"large"} type="primary">Ajukan Sekarang</Button>*/}
                        </h1>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HomeBanner;
