import axios from "axios";
import {baseUrlB2b, xApiKeyB2b} from "../../config/apiConfig/apiConfig";

class B2bService {
    private axios = axios.create({
        baseURL: baseUrlB2b,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async sendData(params: any) {
        try {
            return await this.axios.post<any>('/public/create-loan-survey-order', params, {
                headers: {"Api-Key": xApiKeyB2b}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const b2bService = new B2bService();